// Variables
// This file contains overrides for our plugins
@import 'variables';

// Fonts
// @import url('https://fonts.googleapis.com/css?family=Nunito');
// Custom fonts
/* nunito-regular - latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: local('Nunito Regular'), local('Nunito-Regular'),
        url('../fonts/nunito-v14-latin-regular.woff2') format('woff2'),
        url('../fonts/nunito-v14-latin-regular.woff') format('woff');
}

// CoreUI
@import '~@coreui/coreui/scss/coreui';

.c-sidebar .c-sidebar-nav-link.c-active:not(:hover),
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle:not(:hover) {
    background-color: rgba(255, 255, 255, 0.1);
}

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// DataTables
@import '~datatables.net-bs4/css/dataTables.bootstrap4.min.css';
@import '~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';
@import '~datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css';

table.table.dataTable > tbody > tr > td {
    vertical-align: middle;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}

// set the datetimepicker active to the $primary colour
$bs-datetimepicker-active-bg: $primary;
// Import datepicker component
@import '~tempusdominus-bootstrap-4/src/sass/_tempusdominus-bootstrap-4';

// Toast notifications'
@import '~notyf/notyf.min.css';

// Select 2
@import '~select2/dist/css/select2.min.css';
@import '~@ttskch/select2-bootstrap4-theme/src/select2-bootstrap4'; // bootstrap 4 theme

// image viewer
@import '~viewerjs/dist/viewer.min.css';

// hide overflow from image url on custom-file-label elements
.custom-file-label {
    overflow-x: hidden;
}

.text-underlined {
    text-decoration: underline;
}
